import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http-service/http.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpService) { }

  get_packages(data: { limit?: any, offset?: any, status?: any } = { limit: '', offset: '', status: '' }) {
    let params = new HttpParams().set("limit", data.limit).set("offset", data.offset).set("status", data.status)
    return this.http.get('sms-rates/package', params)
  }

  get_active_prices(data: { limit?: any, offset?: any } = { limit: '', offset: '' }) {
    let params = new HttpParams().set("limit", data.limit).set("offset", data.offset)
    return this.http.get('telco/telco-service-price/active', params)
  }
}
