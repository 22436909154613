<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20"
    class="text-[#777777] my-20 text-xs border-t pt-3">
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="20" fxLayoutGap.lt-md="10">
        <a *ngFor="let link of footer_links" mat-button>{{link.title}}</a>
    </div>
    <div class="mt-4">
        <span>&#169;</span> {{curr_year}} JAMBOPAY. ALL RIGHTS RESERVED
    </div>
    <div>
        REGULATED BY THE CENTRAL BANK OF KENYA
    </div>
    <div fxLayout="column" class="text-center border-[#eae9ea] border-t pt-4">
        <p>Webtribe Ltd Registered in Kenya. Registered office: View Park Towers, 12th Floor, Monrovia Street.</p>
        <p>P.O. Box 15478 00100, Nairobi, Kenya.</p>
    </div>
</div>