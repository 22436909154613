import { trigger, transition, style, animate } from '@angular/animations';
import { Component, HostListener, Input, OnInit, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger(
      'scrollUpDown',
      [
        transition(
          ':enter',
          [
            style({ height: 0, }),
            animate('0.5s ease-out',
              style({ height: '*', }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*', }),
            animate('0.5s ease-out',
              style({ height: 0, }))
          ]
        )
      ]
    ),
  ]
})
export class HeaderComponent implements OnInit {

  @Input() scrollActivity: any

  ngOnChanges(changes: SimpleChanges) {
    //console.log("scrollActivity", changes['scrollActivity'].currentValue);

    var myEvent = changes['scrollActivity'].currentValue

    if (myEvent) {
      var navbar_row = document.getElementById('nav-links')

      if (this.checkScrollDirectionIsUp(myEvent)) {
        navbar_row?.classList.remove("fixed")
        navbar_row?.classList.remove("top-0")
        this.hide_top_nav_row = false
      } else {
        navbar_row?.classList.add("fixed")
        navbar_row?.classList.add("top-0")
        this.hide_top_nav_row = true
      }
    }
  }

  constructor() { }

  logo_url: string = environment.logo_url

  nav_links: { title: string, link: string }[] = [
    { title: 'Products', link: 'products' },
    { title: 'Pricing', link: 'pricing' },
    { title: 'Why Us?', link: 'why_us' },
    { title: 'Developers', link: 'developers' },
  ]

  hide_top_nav_row: boolean = true

  auth_url = environment.auth_url

  ngOnInit(): void {
    this.hide_top_nav_row = false
  }

  /* @HostListener('mousewheel', ['$event'])
  onMousewheel(event: any) {
    console.log(event)
    var navbar_row = document.getElementById('nav-links')

    if (this.checkScrollDirectionIsUp(event)) {
      navbar_row?.classList.remove("fixed")
      navbar_row?.classList.remove("top-0")
      this.hide_top_nav_row = false
    } else {
      navbar_row?.classList.add("fixed")
      navbar_row?.classList.add("top-0")
      this.hide_top_nav_row = true
    }
  } */

  checkScrollDirectionIsUp(event: any) {
    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  }

}
