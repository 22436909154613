import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private formBuilder: FormBuilder) { }

  scrollEvent: any
  contactForm!: FormGroup

  assets_path: string = environment.assets_path
  banner_url: string = this.assets_path + 'contact-bg.png'

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(256)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.maxLength(13)]],
      issue: ['', [Validators.required, Validators.maxLength(256)]],
    })
  }

  @HostListener('mousewheel', ['$event'])
  onMousewheel(event: any) {
    this.scrollEvent = event
  }

}
