<!-- Nav bar -->
<!-- large screen -->
<mat-toolbar fxHide.lt-md="true" fxLayout="row" fxLayoutAlign="space-between center"
    class="border-b-[#d5d4d5] border-2 !bg-white !py-3 !px-20 sticky top-0 z-[2]">

    <a routerLink="" fragment="home"><img class="max-w-[160px]" src="{{logo_url}}" alt="logo"></a>

    <div>
        <a mat-button class="!mx-3" routerLink="" fragment="products">Products</a>
        <a mat-button class="!mx-3" routerLink="" fragment="why_us">Why Us?</a>
        <a mat-button class="!mx-3" routerLink="" fragment="pricing">Pricing</a>
        <a mat-button class="!mx-3" routerLink="" fragment="developers">Developers</a>
        <a mat-button class="!mx-3" routerLink="/help">Help</a>
    </div>

    <a href="{{auth_url}}"
        mat-stroked-button class="!text-sms-light-blue !border-sms-light-blue">Sign In</a>
</mat-toolbar>

<!-- small screen -->
<mat-toolbar fxHide.gt-sm="true" class="border-b-[#d5d4d5] border-2 !bg-white sticky top-0 z-[2]">
    <mat-toolbar-row *ngIf="!hide_top_nav_row" [@scrollUpDown] fxLayout="row" fxLayoutAlign="space-between center"
        class="mb-4">
        <a routerLink="" fragment="home"><img class="max-w-[130px]" src="{{logo_url}}" alt="logo"></a>

        <a href="{{auth_url}}" mat-stroked-button class="!text-sms-light-blue !border-sms-light-blue">Sign In</a>
    </mat-toolbar-row>

    <mat-toolbar-row fxLayout="row" fxLayoutAlign="center center" id="nav-links" class="overflow-hidden z-1000">
        <a mat-button *ngFor="let navlink of nav_links" routerLink="" fragment="{{navlink.link}}">{{navlink.title}}</a>
        <a mat-button routerLink="/help">Help</a>
    </mat-toolbar-row>
</mat-toolbar>
<!-- Nav bar -->