<div id="home" fxLayout="column">


    <app-header [scrollActivity]="scrollEvent" class="sticky top-0 z-[2]"></app-header>

    <!-- Banner -->
    <div fxLayout="row wrap" id="banner-div" fxLayoutAlign="center center" class="pb-12 pt-14">

        <div fxFlex="37" fxFlex.lt-md="100" fxHide.gt-md="true">
            <img src="{{banner_url}}" class="mx-auto" />
        </div>

        <div fxFlex="37" fxFlex.lt-md="100" fxLayout="column" class="p-14 lg:mr-20">
            <p class="text-4xl font-medium !mb-0 text-sms-dark-blue">Empowering businesses to</p>

            <ng-container *ngFor="let tag of tag_lines; let i = index">
                <p [@fadeInOut] class="text-4xl font-medium !mb-0 text-sms-orange" *ngIf="i == active_tag_line">{{tag}}
                </p>
            </ng-container>


            <p class="!my-7 font-normal">Get started today and talk to your customers over any channel, for any use
                case, anywhere in the world.
            </p>

            <div fxLayout="row" fxLayoutGap="15px">
                <a href="{{auth_url}}" mat-flat-button class="!bg-sms-light-blue !text-white">Get Started Now</a>
                <button routerLink="/help" mat-stroked-button
                    class="!text-sms-light-blue !border-2 !border-sms-light-blue">Talk to
                    Sales</button>
            </div>
        </div>

        <div fxFlex="37" fxFlex.lt-md="100" fxHide.lt-md="true">
            <img src="{{banner_url}}" class="mx-auto" />
        </div>

    </div>
    <!-- Banner -->

    <!-- Partners -->
    <div fxLayout="column" fxLayoutAlign="center none" class="bg-[#f9f9f9] px-2 pb-8">
        <p class="text-[#7a7a7a] font-medium text-xs text-center !my-7">TRUSTED BY ESTABLISHED BRANDS</p>

        <div fxLayout="row wrap" fxLayoutAlign="space-evenly center" class="lg:mx-24">
            <img *ngFor="let partner of partners" class="max-w-[100px] mx-3" src="{{partner.logo_url}}"
                alt="{{partner.name}}" matTooltip="{{partner.name}}">
        </div>
    </div>
    <!-- Partners -->

    <!-- Products -->
    <div id="products" fxLayout="column" fxLayoutAlign="center center" class="my-6">
        <h3 class="text-sms-light-blue !font-semibold !text-sm !my-7">OUR PRODUCTS</h3>

        <p class="text-3xl font-medium text-sms-dark-blue max-w-sm text-center">Choose the right tool for your business
        </p>

        <div fxLayout="row wrap" fxLayoutAlign="center stretch" class="mt-6">
            <!-- <mat-card class="m-6 lg:mx-14 mat-elevation-z4 !p-5 !pt-10" fxFlex="20" fxFlex.lt-lg="35" fxFlex.lt-md="90">
                <div fxLayout="column" fxLayoutAlign="center center"
                    class="absolute w-[60px] h-[60px] bg-[#e2e1ff] bottom-[228px] sm:bottom-[192px] md:bottom-[165px] lg:bottom-[248px]">
                    <img src="{{bulksms_icon_sm_url}}" class="max-w-[30px]" alt="" />
                </div>
                <mat-card-title class="text-lg !mb-5">Bulk SMS</mat-card-title>
                <mat-card-content>Bulk SMS is the dissemination of large numbers of SMS messages for delivery to
                    mobile phone terminals. It is used by media companies, banks and other enterprises (for marketing
                    and fraud control) and consumer brands for a variety of purposes including entertainment,
                    enterprise and mobile marketing.</mat-card-content>
                <mat-card-actions>
                    <a href="{{auth_url}}" mat-flat-button class="!bg-green-700 !text-white">Sign me up</a>
                </mat-card-actions>
            </mat-card> -->

            <div fxLayout="column" fxLayoutAlign=" start" fxFlex="20" fxFlex.lt-lg="35" fxFlex.lt-md="90"
                class="m-6 lg:mx-14 ">
                <div fxLayout="column" fxLayoutAlign="center center"
                    class="w-[60px] h-[60px] bg-[#e2e1ff] ml-4 bottom-[-20px] relative z-[1]">
                    <img src="{{bulksms_icon_sm_url}}" class="max-w-[30px]" alt="" />
                </div>
                <mat-card class="mat-elevation-z4 !p-5 !pt-10 min-h-[320px]">
                    <mat-card-title class="text-lg !mb-5">Bulk SMS</mat-card-title>
                    <mat-card-content>Utilize Text messaging to conveniently reach customers, with high delivery rates and
                        personalized messages. We provide you with an easy to use and a reliable Bulk SMS platform.
                        Send Bulk Promotional or Transactional SMS messages to individuals, groups or database
                        recipients at the touch of a button.</mat-card-content>
                    <mat-card-actions>
                        <a href="{{auth_url}}" mat-flat-button class="!bg-green-700 !text-white">Sign me up</a>
                    </mat-card-actions>
                </mat-card>
            </div>

            <div fxLayout="column" fxLayoutAlign=" start" fxFlex="20" fxFlex.lt-lg="35" fxFlex.lt-md="90"
                class="m-6 lg:mx-14 ">
                <div fxLayout="column" fxLayoutAlign="center center"
                    class="w-[60px] h-[60px] bg-[#fcede8] ml-4 bottom-[-20px] relative z-[1]">
                    <img src="{{ussd_icon_sm_url}}" class="max-w-[30px]" alt="" />
                </div>
                <mat-card class="mat-elevation-z4 !p-5 !pt-10 min-h-[320px]">
                    <mat-card-title class="text-lg !mb-5">USSD</mat-card-title>
                    <mat-card-content>Build your desired USSD application and allow your customers to perform transactions
                        through both feature phones and smartphones. E.g *805#. With the high cost of data and low penetration of smartphones, USSD is a cost-effective
                        channel for All mobile phone users to interact with your systems. Integrate with our user-friendly USSD API and offer effective solutions to your customers.</mat-card-content>
                    <mat-card-actions>
                        <a href="{{auth_url}}" mat-flat-button class="!bg-green-700 !text-white">Sign me up</a>
                    </mat-card-actions>
                </mat-card>
            </div>

            <div fxLayout="column" fxLayoutAlign=" start" fxFlex="20" fxFlex.lt-lg="35" fxFlex.lt-md="90"
                class="m-6 lg:mx-14 ">
                <div fxLayout="column" fxLayoutAlign="center center"
                    class="w-[60px] h-[60px] bg-[#d3fcfd] ml-4 bottom-[-20px] relative z-[1]">
                    <img src="{{shortcode_icon_sm_url}}" class="max-w-[30px]" alt="" />
                </div>
                <mat-card class="mat-elevation-z4 !p-5 !pt-10 min-h-[320px]">
                    <mat-card-title class="text-lg !mb-5">Shortcode</mat-card-title>
                    <mat-card-content>Increase engagement with your customers using SMS with a 5-digit Shortcode number.
                        The short code can be used to both send and receive messages from customers and is the
                        best tool for businesses when communicating with customers or prospects for feedback
                        collection. Communicate in a quicker, convenient and efficient way by a customized brand name.</mat-card-content>
                    <mat-card-actions>
                        <a href="{{auth_url}}" mat-flat-button class="!bg-green-700 !text-white">Sign me up</a>
                    </mat-card-actions>
                </mat-card>
            </div>

            <!-- <mat-card class="m-6 lg:mx-14 mat-elevation-z4 !p-5 !pt-10" fxFlex="20" fxFlex.lt-lg="35" fxFlex.lt-md="90">
                <div fxLayout="column" fxLayoutAlign="center center"
                    class="absolute w-[60px] h-[60px] bg-[#fcede8] bottom-[228px] sm:bottom-[192px] md:bottom-[165px] lg:bottom-[248px]">
                    <img src="{{ussd_icon_sm_url}}" class="max-w-[30px]" alt="" />
                </div>
                <mat-card-title class="text-lg !mb-5">USSD</mat-card-title>
                <mat-card-content>USSD can be used for WAP browsing, prepaid callback service, mobile-money services,
                    location-based content services, menu-based information services, and as part of configuring the
                    phone on the network.</mat-card-content>
                <mat-card-actions>
                    <a href="{{auth_url}}" mat-flat-button class="!bg-green-700 !text-white">Sign me up</a>
                </mat-card-actions>
            </mat-card>

            <mat-card class="m-6 lg:mx-14 mat-elevation-z4 !p-5 !pt-10" fxFlex="20" fxFlex.lt-lg="35" fxFlex.lt-md="90">
                <div fxLayout="column" fxLayoutAlign="center center"
                    class="absolute w-[60px] h-[60px] bg-[#d3fcfd] bottom-[228px] sm:bottom-[192px] md:bottom-[165px] lg:bottom-[248px]">
                    <img src="{{shortcode_icon_sm_url}}" class="max-w-[30px]" alt="" />
                </div>
                <mat-card-title class="text-lg !mb-5">Shortcodes</mat-card-title>
                <mat-card-content>Short codes are widely used for value-added services such as charity donations, mobile
                    services, ordering ringtones, and television program voting.</mat-card-content>
                <mat-card-actions>
                    <a href="{{auth_url}}" mat-flat-button class="!bg-green-700 !text-white">Sign me up</a>
                </mat-card-actions>
            </mat-card> -->
        </div>
    </div>
    <!-- Products -->

    <!-- Value proposition -->
    <div id="why_us" fxLayout="column" fxLayoutAlign=" center" class="py-6 lg:px-16 bg-[#f9f9f9]">
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="95">
            <div fxFlex="35" fxFlex.lt-md="100">
                <img src="{{banner2_url}}" class="mx-auto" />
            </div>
            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="43" fxFlex.lt-md="90" class="my-6">
                <h3 class="text-sms-light-blue !font-semibold !text-sm">WHY US?</h3>

                <p class="text-3xl font-medium text-sms-dark-blue ">This is why we're Kenya's leading
                    SMS provider</p>

                <mat-list>
                    <mat-list-item *ngFor="let proposition of value_propositions">
                        <mat-icon class="text-green-700 mr-2">check_circle</mat-icon>
                        <span class="text-sms-light-blue">{{proposition}}</span>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>

        <a href="{{auth_url}}" mat-flat-button class="!bg-sms-light-blue !text-white text-xl !my-10">Get Started Now</a>
    </div>
    <!-- Value proposition -->

    <!-- Pricing -->
    <div id="pricing" fxLayout="column" fxLayoutAlign="center none" fxLayoutGap="35"
        class="p-1 lg:p-10 md:p-10 lg:mx-32">
        <h3 class="text-sms-light-blue !font-semibold !text-sm text-center">PRICING</h3>

        <p class="text-4xl font-medium text-sms-dark-blue text-center">Pricing fit for everyone</p>

        <div fxLayout="column" fxLayoutAlign="center center">
            <p class="max-w-sm text-center">Simple and flexible pricing structure with outstanding support included.</p>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="50px" fxHide.lt-md="true" class="lg:px-20">
            <img class="max-w-[85px] mx-7 cursor-pointer" [class.mat-elevation-z3]="selectedTabIndex == 0"
                src="{{bulksms_icon_url}}" alt="" (click)="selectedTabIndex = 0">
            <img class="max-w-[85px] mx-7 cursor-pointer" [class.mat-elevation-z3]="selectedTabIndex == 1"
                src="{{shortcode_icon_url}}" alt="" (click)="selectedTabIndex = 1">
            <img class="max-w-[85px] mx-7 cursor-pointer" [class.mat-elevation-z3]="selectedTabIndex == 2"
                src="{{senderid_icon_url}}" alt="" (click)="selectedTabIndex = 2">
            <img class="max-w-[85px] mx-7 cursor-pointer" [class.mat-elevation-z3]="selectedTabIndex == 3"
                src="{{ussd_icon_url}}" alt="" (click)="selectedTabIndex = 3">
        </div>

        <mat-tab-group mat-align-tabs="" color="accent" [(selectedIndex)]="selectedTabIndex"
            class="pricing-tabgroup max-w-full">
            <mat-tab label="Bulk SMS">
                <div fxLayout="row wrap" fxLayoutAlign="space-evenly start" fxLayoutGap=""
                    class="px-1 lg:px-2 md:px-8 py-16">

                    <table fxFlex="35" fxFlex.lt-md="90" *ngIf="safaricom_packages.length > 0"
                        class="mat-elevation-z6 text-xs border-collapse text-center rounded-b-lg m-4">
                        <thead class="text-slate-600 bg-[#edeef1]">
                            <tr class="border-[#d1d2d5] border-b-2">
                                <th class="p-3" colspan="2">
                                    <img class="max-h-[75px] mx-auto" src="{{safaricom_logo_url}}" alt="image" />
                                </th>
                            </tr>
                            <tr>
                                <th class="p-3 font-normal">No. of Messages</th>
                                <th class="p-3 font-normal">Price per messages (16% VAT inclusive)</th>
                            </tr>
                        </thead>
                        <tbody class="text-slate-500">
                            <tr *ngFor="let package of safaricom_packages">
                                <td class="p-3 border-[#9f9f9f] border-r border-b">{{package.min_volume | number: '1.'}}
                                    - {{package.max_volume | number: '1.'}}</td>
                                <td class="p-3 border-[#9f9f9f] border-b">{{package.rate | currency}}</td>
                            </tr>

                        </tbody>
                    </table>

                    <!-- <table fxFlex="35" fxFlex.lt-md="90"
                        class="mat-elevation-z6 text-xs border-collapse text-center rounded-b-lg m-4">
                        <thead class="text-slate-600 bg-[#edeef1]">
                            <tr class="border-[#d1d2d5] border-b-2">
                                <th class="p-3" colspan="2">
                                    <img class="max-h-[75px] mx-auto" src="{{safaricom_logo_url}}" alt="image" />
                                </th>
                            </tr>
                            <tr>
                                <th class="p-3 font-normal">No. of Messages</th>
                                <th class="p-3 font-normal">Price per messages (16% VAT inclusive)</th>
                            </tr>
                        </thead>
                        <tbody class="text-slate-500">
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">Below 20,000</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 0.5</td>
                            </tr>
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">20,000 - 79,999</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 0.45</td>
                            </tr>
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r">80,000 - 499,999</td>
                                <td class="p-3 border-[#9f9f9f]">KES 0.4</td>
                            </tr>
                        </tbody>
                    </table> -->

                    <table fxFlex="35" fxFlex.lt-md="90" *ngIf="airtel_packages.length > 0"
                        class="mat-elevation-z6 text-xs border-collapse text-center rounded-b-lg m-4">
                        <thead class="text-slate-600 bg-[#edeef1]">
                            <tr class="border-[#d1d2d5] border-b-2">
                                <th class="p-3" colspan="2">
                                    <img class="max-h-[75px] mx-auto" src="{{airtel_logo_url}}" alt="image" />
                                </th>
                            </tr>
                            <tr>
                                <th class="p-3 font-normal">No. of Messages</th>
                                <th class="p-3 font-normal">Price per messages (16% VAT inclusive)</th>
                            </tr>
                        </thead>
                        <tbody class="text-slate-500">
                            <tr *ngFor="let package of airtel_packages">
                                <td class="p-3 border-[#9f9f9f] border-r border-b">{{package.min_volume | number: '1.'}}
                                    - {{package.max_volume | number: '1.'}}</td>
                                <td class="p-3 border-[#9f9f9f] border-b">{{package.rate | currency}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- <table fxFlex="35" fxFlex.lt-md="90"
                        class="mat-elevation-z6 text-xs border-collapse text-center rounded-b-lg m-4">
                        <thead class="text-slate-600 bg-[#edeef1]">
                            <tr class="border-[#d1d2d5] border-b-2">
                                <th class="p-3" colspan="2">
                                    <img class="max-h-[75px] mx-auto" src="{{airtel_logo_url}}" alt="image" />
                                </th>
                            </tr>
                            <tr>
                                <th class="p-3 font-normal">No. of Messages</th>
                                <th class="p-3 font-normal">Price per messages (16% VAT inclusive)</th>
                            </tr>
                        </thead>
                        <tbody class="text-slate-500">
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">Below 20,000</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 0.5</td>
                            </tr>
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">20,000 - 79,999</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 0.45</td>
                            </tr>
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r">80,000 - 499,999</td>
                                <td class="p-3 border-[#9f9f9f]">KES 0.4</td>
                            </tr>
                        </tbody>
                    </table> -->
                </div>
            </mat-tab>

            <mat-tab label="Shortcode">
                <div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="" class="px-1 lg:px-8 md:px-8 py-16">
                    <table fxFlex="90" fxFlex.lt-md="90"
                        class="mat-elevation-z6 border-collapse text-center rounded-b-lg m-4">
                        <thead class="bg-[#edeef1] font-semibold">
                            <tr>
                                <th class="p-3 border-[#9f9f9f] border-r">Purpose</th>
                                <th class="p-3 border-[#9f9f9f] border-r">Range Allocated</th>
                                <th class="p-3 border-[#9f9f9f]">Monthly Maintenance Fee (Incl. VAT)</th>
                            </tr>
                        </thead>
                        <tbody class="text-slate-500">
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">General content shortcode</td>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">20xxx, 21xxx, 22xxx</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 15,000</td>
                            </tr>
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">Banking shortcodes</td>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">16110 - 16199</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 15,000</td>
                            </tr>
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">Directory shortcodes</td>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">19100-19149</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 15,000</td>
                            </tr>
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">Gaming/lottery shortcodes</td>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">29xxx</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 15,000</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mat-tab>

            <mat-tab fxLayout="column" label="Sender ID">
                <div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="" class="px-1 lg:px-8 md:px-8 py-16">
                    <table fxFlex="90" fxFlex.lt-md="90" *ngIf="sender_id_prices.length > 0"
                        class="mat-elevation-z6 border-collapse text-center rounded-b-lg m-4">
                        <thead class="bg-[#edeef1] font-semibold">
                            <tr>
                                <th class="p-3 border-[#9f9f9f] border-r">Telco</th>
                                <th class="p-3 border-[#9f9f9f] border-r">Setup Fee</th>
                            </tr>
                        </thead>
                        <tbody class="text-slate-500">
                            <tr *ngFor="let price of sender_id_prices">
                                <td class="p-3 border-[#9f9f9f] border-r border-b">{{price.telco.name}}</td>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">{{price.price | currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <p class="mx-6 text-slate-500 text-lg italic">*Prices are 16% VAT inclusive</p>
            </mat-tab>

            <mat-tab label="USSD">
                <div fxLayout="row wrap" fxLayoutAlign="space-evenly start" fxLayoutGap=""
                    class="px-1 lg:px-8 md:px-8 py-16">
                    <table fxFlex="40" fxFlex.lt-md="90"
                        class="mat-elevation-z6 border-collapse text-center rounded-b-lg m-4">
                        <thead class="bg-[#edeef1] font-semibold">
                            <tr>
                                <th colspan="2" class="p-3 border-[#9f9f9f] border-b">MONTHLY MAINTENANCE FEES</th>
                            </tr>
                            <tr>
                                <th class="p-3 border-[#9f9f9f] border-r border-b font-normal">Tariff</th>
                                <th class="p-3 border-[#9f9f9f] border-b font-normal">Price (Tax Excluded)</th>
                            </tr>
                        </thead>
                        <tbody class="text-slate-500">
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">Prepay (MNO Fees)</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 35,000</td>
                            </tr>
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">Hosting Fee</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 20,000</td>
                            </tr>
                        </tbody>
                    </table>

                    <table fxFlex="40" fxFlex.lt-md="90"
                        class="mat-elevation-z6 border-collapse text-center rounded-b-lg m-4">
                        <thead class="bg-[#edeef1] font-semibold">
                            <tr>
                                <th colspan="2" class="p-3 border-[#9f9f9f] border-b">SESSION FEES</th>
                            </tr>
                            <tr>
                                <th class="p-3 border-[#9f9f9f] border-r border-b font-normal">Rate applicable to:</th>
                                <th class="p-3 border-[#9f9f9f] border-b font-normal">Price per Session</th>
                            </tr>
                        </thead>
                        <tbody class="text-slate-500">
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">Financial institutions</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 1</td>
                            </tr>
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">Content</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 1</td>
                            </tr>
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">Post-pay tariff</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 2 (Cost incurred by user)</td>
                            </tr>
                        </tbody>
                    </table>

                    <table fxFlex="40" fxFlex.lt-md="90"
                        class="mat-elevation-z6 border-collapse text-center rounded-b-lg m-4">
                        <thead class="bg-[#edeef1] font-semibold">
                            <tr>
                                <th colspan="2" class="p-3 border-[#9f9f9f] border-b">TEST BED FEES</th>
                            </tr>
                            <tr>
                                <th class="p-3 border-[#9f9f9f] border-r border-b font-normal">Rate applicable to:</th>
                                <th class="p-3 border-[#9f9f9f] border-b font-normal">Price (Tax Excl.)</th>
                            </tr>
                        </thead>
                        <tbody class="text-slate-500">
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">Connection fees</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 250,000</td>
                            </tr>
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">Test-bed fees</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 15,000 (valid for 1 month)</td>
                            </tr>
                            <tr>
                                <td class="p-3 border-[#9f9f9f] border-r border-b">Renewal of test-bed fees</td>
                                <td class="p-3 border-[#9f9f9f] border-b">KES 20,000 (Chargeable after 1 month on Test bed)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mat-tab>
        </mat-tab-group>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20">
            <a href="{{auth_url}}" mat-flat-button class="!bg-green-600 !text-white !my-10">Get Started Now</a>
            <button routerLink="/help" mat-stroked-button
                class="!border-green-600 !border-2 !text-green-600 !my-10">Talk to Sales</button>
        </div>
    </div>
    <!-- Pricing -->

    <!-- Developers -->
    <div id="developers" fxLayout="column" fxLayoutAlign="center none" class="py-6 lg:px-14 bg-[#f9f9f9]">
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap.gt-md="45">
            <div fxFlex="40" fxFlex.lt-md="95" class="">
                <!-- <img src="https://www.clipartmax.com/png/middle/92-925246_window-cleaning-placeholder-icon-png.png" /> -->
                <app-code-snippet [code]="sample_code"></app-code-snippet>
            </div>

            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="40" fxFlex.lt-md="95" class="my-6">
                <h3 class="text-sms-light-blue !font-semibold !text-sm">DEVELOPERS</h3>

                <p class="text-4xl font-medium text-sms-dark-blue">Automate Communication</p>

                <p class="text-xl">Getting started with our SMS API is easy:</p>

                <mat-list>
                    <mat-list-item *ngFor="let proposition of value_propositions; let c = index">
                        <span
                            class="mr-2 bg-green-700 text-white rounded-full p-[2px] text-[12px] !h-[21px] !w-[21px] text-center">{{c+1}}</span>
                        <span class="text-sms-light-blue text-lg max-w-[90%] md:max-w-none">{{proposition}}</span>
                    </mat-list-item>
                </mat-list>

                <a href="{{auth_url}}" mat-flat-button class="!bg-sms-light-blue !text-white text-xl !my-10">Get Started
                    Now</a>

            </div>
        </div>

    </div>
    <!-- Developers -->


    <!-- CTA -->
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="35" class="bg-sms-light-blue py-16 px-4">
        <span class="text-white text-4xl font-semibold text-center">Get Started Today</span>
        <a href="{{auth_url}}" mat-stroked-button class="!text-sms-dark-blue !bg-white ">Sign me up</a>
    </div>
    <!-- CTA -->

    <!-- Footer -->
    <app-footer></app-footer>
    <!-- Footer -->

</div>