// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_base_url: 'https://sms-poc.jambopay.co.ke/api',
  assets_path: '../../assets/',
  logo_url: '../../assets/jp_prsp_logo.png',
  auth_url: 'https://accounts.jambopay.com/v2/auth/authorize?client_id=e7c4dcd1049e350e0136fbffa7a8c705508a54036e16eae94ab0f0c3c52ea9aa&response_type=code&scope=email&redirect_uri=https://sms-poc.jambopay.co.ke/app/login&nonce=123',
};
// https://accounts.jambopay.com/v2/auth/authorize?client_id=IztyLVQpDVvQ3naT9i4QxX50LtZUtetOLtU6dnKMqoM=&response_type=code&scope=email&redirect_uri=http://localhost:4200/login&nonce=123
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
