<div fxLayout="column">
    <app-header [scrollActivity]="scrollEvent" class="sticky top-0 z-[2]"></app-header>

    <div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="45" class="p-4 lg:p-16 md:p-16">

        <div fxLayout="column" fxLayoutAlign="center center" class="w-full bg-cover banner">
            <div fxLayout="column" fxLayoutAlign=" center" class="max-w-sm text-center my-5 bg-white p-5">
                <p class="text-sms-orange">GOT A QUESTION?</p>

                <h3 class="!text-4xl">Contact Us</h3>

                <p class="text-sms-light-blue">We're here to help and answer any question you might have. We look
                    forward to
                    hearing from you.</p>
            </div>
        </div>

        <div fxLayout="row wrap" class="w-full" fxLayoutAlign="center start" fxLayoutGap="40">

            <div fxLayout="column" fxFlex="10" fxHide.lt-lg="true">

            </div>

            <div fxLayout="column" fxLayoutGap="20" fxFlex="25" fxFlex.lt-lg="30" fxFlex.lt-md="80">
                <div>
                    <p class="text-xl">Office Location</p>

                    <div fxLayout="row" fxLayoutGap="10" class="text-sms-light-blue">
                        <mat-icon>place</mat-icon>
                        <div>
                            <p>View Park Towers, 12th Floor</p>
                            <p>Monrovia Street, Nairobi.</p>
                        </div>
                    </div>
                </div>

                <div>
                    <p class="text-xl">Phone Number</p>

                    <div fxLayout="row" fxLayoutGap="10" class="text-sms-light-blue">
                        <mat-icon>phone</mat-icon>
                        <p>+254 709 920 000</p>
                    </div>
                </div>

                <div>
                    <p class="text-xl">Company Email</p>

                    <div fxLayout="row" fxLayoutGap="10" class="text-sms-light-blue">
                        <mat-icon>email</mat-icon>
                        <p>info@webtribe.co.ke</p>
                    </div>
                </div>
            </div>



            <div fxLayout="column" fxFlex="40" fxFlex.lt-lg="45" fxFlex.lt-md="90">
                <p fxHide.gt-sm="true">
                    <br /><br />
                    <mat-divider></mat-divider>
                    <br /><br />
                </p>

                <h3 class="!text-xl !font-normal">Send Us a Message</h3>
                <form fxLayout="column" [formGroup]="contactForm">

                    <mat-form-field appearance="outline">
                        <mat-label>Enter Your Name</mat-label>
                        <input matInput placeholder="e.g. John Doe" formControlName="name">
                        <mat-error *ngIf="contactForm.controls['name'].invalid">
                            <span *ngIf="contactForm.controls['name'].errors?.['required']">Name is required</span>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Enter Your Email Address</mat-label>
                        <input type="email" matInput placeholder="e.g. jdoe@example.com" formControlName="email">
                        <mat-error *ngIf="contactForm.controls['email'].invalid">
                            <span *ngIf="contactForm.controls['email'].errors?.['required']">Email address is
                                required</span>
                            <span *ngIf="contactForm.controls['email'].errors?.['email']">Please enter a valid
                                email</span>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Enter Your Phone Number</mat-label>
                        <input matInput placeholder="e.g. 0711111111" formControlName="phone">
                        <mat-error *ngIf="contactForm.controls['phone'].invalid">
                            <span *ngIf="contactForm.controls['phone'].errors?.['required']">Phone number is
                                required</span>
                            <span *ngIf="contactForm.controls['phone'].errors?.['maxlength']">Max length is 13</span>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>How can we help you?</mat-label>
                        <textarea matInput placeholder="Describe your issue or query" rows="4"
                            formControlName="issue"></textarea>
                        <mat-error *ngIf="contactForm.controls['issue'].invalid">
                            <span *ngIf="contactForm.controls['issue'].errors?.['required']">Please describe your
                                issue</span>
                            <span *ngIf="contactForm.controls['issue'].errors?.['maxlength']">Max length is 256</span>
                        </mat-error>
                    </mat-form-field>

                    <div fxLayout="row">
                        <button mat-raised-button type="submit" class="!bg-sms-light-blue !text-white">Send</button>
                    </div>

                </form>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>