import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  footer_links: { title: string, link: string }[] = [
    { title: 'ABOUT', link: '' },
    { title: 'PRIVACY', link: '' },
    { title: 'TERMS AND CONDITIONS', link: '' },
    { title: 'DEVELOPERS', link: '' },
    { title: 'SUPPORT', link: '' },
  ]

  curr_year = (new Date()).getFullYear();

  ngOnInit(): void {
  }

}
