import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  get(endpoint: string, params?: HttpParams) {
    return this.http.get(this.getUrl(endpoint), { params: params });
  }

  getUrl(endpoint: string): string {
    return `${environment.api_base_url}/${endpoint}`;
  }
}
