import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
//import { EventEmitter } from 'stream';
import { ApiService } from '../services/api-service/api.service';

export interface PlanObject {
  id: any;
  title: string;
  description: string;
  plan_type: string;
}

export interface CountryObject {
  id: any;
  country_code: string;
  country_name: string;
}

export interface TelcoObject {
  id: any;
  name: string;
  telco_code: string;
  country: CountryObject;
  is_active: boolean;
}

export interface PackageObject {
  id: any;
  plan: PlanObject;
  telco: TelcoObject
  min_volume: number;
  max_volume: number;
  rate: number;
  validity: number;
  status: boolean;
}

export interface TelcoPriceObject {
  id: string;
  telco: TelcoObject;
  service_type: string;
  price: number;
  status: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger(
      'fadeInOut',
      [
        transition(':enter', [
          style({ opacity: 0 }),
          animate('0.7s ease-in',
            style({ opacity: 1 }))
        ]),
        /* transition(':leave', [
          animate('0.7s ease-in',
            style({transform: 'translateX(100%)'}))
        ]), */
      ]
    )
  ]
})
export class HomeComponent implements OnInit {

  //@Output() newScrollEvent = new EventEmitter();

  constructor(private api: ApiService, private route: ActivatedRoute, private snackbar: MatSnackBar) { }

  logo_url: string = environment.logo_url
  assets_path: string = environment.assets_path
  banner_url: string = this.assets_path + 'main_banner.svg'
  banner2_url: string = this.assets_path + 'Group 169668.svg'
  bomet_logo_url: string = this.assets_path + '67656828_1607388269391724_9206808285024354304_n.svg'
  imax_logo_url: string = this.assets_path + 'BLUE-IMAX-LOGO.svg'
  lipalater_logo_url: string = this.assets_path + 'Group 162688.svg'
  kenyapower_logo_url: string = this.assets_path + 'Kenya Power logo 2011.svg'
  nairobiwater_logo_url: string = this.assets_path + 'nai-water.svg'
  tatu_logo_url: string = this.assets_path + 'Tatu.svg'
  viupay_logo_url: string = this.assets_path + 'VIUPAY-LOGO.svg'

  bulksms_icon_url: string = this.assets_path + 'bulksms-logo-big.png'
  senderid_icon_url: string = this.assets_path + 'senderid-logo-big.png'
  ussd_icon_url: string = this.assets_path + 'ussd-logo-big.png'
  shortcode_icon_url: string = this.assets_path + 'shortcode-logo-big.png'
  airtel_logo_url: string = this.assets_path + 'Airtel.svg'
  safaricom_logo_url: string = this.assets_path + 'Safaricom.svg'

  bulksms_icon_sm_url: string = this.assets_path + 'bulk-logo.png'
  ussd_icon_sm_url: string = this.assets_path + 'ussd-logo.png'
  shortcode_icon_sm_url: string = this.assets_path + 'shortcode-logo.png'


  last_scroll_pos: number = window.pageYOffset || document.documentElement.scrollTop
  scrollEvent: any

  section: string | undefined

  packages: PackageObject[] = []
  safaricom_packages: PackageObject[] = []
  airtel_packages: PackageObject[] = []

  telco_prices: TelcoPriceObject[] = []
  sender_id_prices: TelcoPriceObject[] = []

  @ViewChild('home', { static: true })
  home!: HTMLElement;
  @ViewChild('products', { static: true })
  _products!: HTMLElement;
  @ViewChild('why_us', { static: true })
  why_us!: HTMLElement;
  @ViewChild('pricing', { static: true })
  pricing!: HTMLElement;
  @ViewChild('developers', { static: true })
  developers!: HTMLElement;



  partners: { logo_url: string, name: string }[] = [
    { logo_url: this.bomet_logo_url, name: 'Bomet County Government' },
    { logo_url: this.imax_logo_url, name: 'IMAX' },
    { logo_url: this.lipalater_logo_url, name: 'Lipa Later' },
    { logo_url: this.kenyapower_logo_url, name: 'KPLC' },
    { logo_url: this.nairobiwater_logo_url, name: 'Nairobi Water' },
    { logo_url: this.tatu_logo_url, name: 'Tatu City' },
    { logo_url: this.viupay_logo_url, name: 'Viupay' },
  ]

  products: { icon: string, icon_color: string, bg_color: string, name: string, description: string }[] = [
    { icon: 'textsms', bg_color: '#e2e1ff', icon_color: '#393fa9', name: 'Bulk SMS', description: 'The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan. A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally bred for hunting.' },
    { icon: 'tag', bg_color: '#fcede8', icon_color: '#ef7c5a', name: 'USSD', description: 'The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan. A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally bred for hunting.' },
    { icon: 'pin', bg_color: '#d3fcfd', icon_color: '#64e2f1', name: 'Shortcodes', description: 'The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan. A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally bred for hunting.' },
  ]

  value_propositions: string[] = [
    'Secure and easy-to-use SMS platform.',
    'Perfect for marketing campaigns and transactional messages.',
    'Get live support.'
  ]



  sample_code: { language: string, source_code: string }[] = [
    {
      language: 'Python', source_code: `
fruits = ["apple", "banana", "cherry"]
for x in fruits:
  print(x)  
  
`},
    {
      language: 'Node', source_code: `
var http = require('http');

http.createServer(function (req, res) {
  res.writeHead(200, {'Content-Type': 'text/plain'});
  res.end('Hello World!');
}).listen(8080);

`
    },
    {
      language: 'C#', source_code: `
class Program
{
    static void Main(string[] args)
    {
        string message = "Hello World!!";

        Console.WriteLine(message);
    }
}

`
    },
    {
      language: 'PHP', source_code: `
<?php
$color = "red";
echo "My car is " . $color . "<br>";
echo "My house is " . $COLOR . "<br>";
echo "My boat is " . $coLOR . "<br>";
?>

`
    },
    {
      language: 'Java', source_code: `
public class Main {
  public static void main(String[] args) {
    System.out.println("Hello World");
  }
}

`
    },
  ]

  tabs: number[] = [0, 1, 2, 3]
  selectedTabIndex: number = 0

  tag_lines: string[] = [
    "increase reach",
    "boost sales",
    "communicate effectively",
  ]

  active_tag_line: number = 0

  auth_url = environment.auth_url

  ngOnInit(): void {


    this.get_packages()
    this.get_prices()

    setInterval(() => {
      ++this.active_tag_line

      if (this.active_tag_line >= this.tag_lines.length) {
        this.active_tag_line = 0;
      }
    }, 3000);

    if (this.route.snapshot.queryParamMap.has('login')) {
      if (this.route.snapshot.queryParamMap.get('login') == 'true') {
        this.snackbar.open('Session expired. Please sign in again.', 'Sign In', { duration: 10000 }).onAction().subscribe(() => {
          window.location.replace(environment.auth_url)
        })
      }
    } else if (this.route.snapshot.queryParamMap.has('logout')) {
      if (this.route.snapshot.queryParamMap.get('logout') == 'true') {
        this.snackbar.open('You have been logged out.', 'Dismiss', { duration: 5000 })
      }
    }


  }







  get_packages() {

    this.api.get_packages({ limit: 1000, offset: 0, status: true }).subscribe({
      next: (data: any) => {

        if (data) {
          this.packages = data.results

          this.packages.forEach(p => {
            if (p.telco.name.indexOf('Safaricom') > -1) {
              this.safaricom_packages.push(p)
            } else if (p.telco.name.indexOf('Airtel') > -1) {
              this.airtel_packages.push(p)
            }
          })
        }
      },
      error: (error) => {

        switch (error.status) {
          default:


            break
        }
      },
      complete: () => {

      }
    })
  }

  get_prices() {

    this.api.get_active_prices({ limit: 1000, offset: 0 }).subscribe({
      next: (data: any) => {

        if (data) {
          this.telco_prices = data.results

          this.telco_prices.forEach(p => {
            if (p.service_type.indexOf('Sender') > -1) {
              this.sender_id_prices.push(p)
            }
          })
        }
      },
      error: (error) => {

        switch (error.status) {
          default:

            break
        }
      },
      complete: () => {

      }
    })
  }

  @HostListener('mousewheel', ['$event'])
  onMousewheel(event: any) {
    this.scrollEvent = event
  }

}
